<template>
  <div v-html="html" />
</template>

<script>
export default {
  props: {
    html: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/variables";

.let-us-help-section {
  text-align: left;
  * {
    box-sizing: border-box;
  }
  h2 {
    font-weight: var(--font-weight--medium);
    padding-bottom: var(--spacer-10);
    @include for-mobile {
      text-align: center;
      padding: 0 var(--spacer-15) 0 var(--spacer-15);
    }
  }
  .let-us-help-wrap {
    display: var(--display-flex);
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    @include for-mobile {
      gap: 10px;
      padding: 0 var(--spacer-sm);
    }
    .let-us-help-single {
      position: relative;
      overflow: hidden;
      max-width: 100%;
      .img {
        @include for-mobile {
          padding-top: 29%;
        }
        img {
          position: absolute;
          border-radius: 10px;
          inset: 0;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      a {
        position: absolute;
        width: 100%;
        left: 0;
        top:0;
        bottom:0;
        height: 100;
        label  {
          display: none;
        }
      }
      width: calc(25% - 24px);
      color: #fff;
      font-size: var(--font-size--base);
      font-weight: 900;
      height: 324px;
      border: 0px solid #4c3772;
      border-radius: 10px;
      display: var(--display-flex);
      @include for-mobile {
        height: 125px;
        width: 100%;
        max-width: 100%;
        height: 100%;
        overflow: hidden;
      }
      &:nth-child(2),
      &:nth-child(4) {
        a {
          @include for-mobile {
            padding-left: 70px;
          }
        }
      }
      &:nth-child(3),
      &:nth-child(1) {
        a {
          @include for-mobile {
            display: var(--display-flex);
            align-content: end;
            flex-direction: column;
            flex-wrap: wrap;
          }
        }
      }
      .let-us-help-title {
        font-weight: var(--font-weight--medium);
        font-size: 26px;
        color: var(--c-white);
        display: var(--display-block);
        margin-top: auto;
        cursor: pointer;
        @include for-mobile {
          font-size: var(--font-size--lg);
          line-height: 1.1;
        }
      }
      .let-us-help-cta {
        display: var(--display-block);
        padding-top: 5px;
        cursor: pointer;
        font-style: normal;
        font-weight: 100;
        font-size: var(--font-size--base);
        line-height: 19px;
        color: var(--c-white);
        text-decoration: none;
        @include for-mobile {
          font-weight: 900;
          font-size: var(--font-size--sm);
          line-height: 1.1;
          width: fit-content;
          color: #4c3772;
        }
      }
      .help-image-secondary {
        display: var(--display-none);
        position: var(--absolute);
        height: 140px;
        bottom: -60px;
        left: -60px;
        @include for-mobile {
          display: var(--display-block);
        }
      }
    }
  }
}
</style>
