<template>
  <div class="home-tabs-wrap" v-if="homeTabs && homeTabs.length">
    <SfTabs :open-tab="1" id="home-product-tabs" ref="homeTabs" class="home__tabs">
      <SfTab v-for="(tab, index) in homeTabs" :key="tab.title" :title="tab.title">
        <div class="swiper-slider-wrap">
          <div class="swiper directive" v-swiper="swiperOption" :instance-name="`silderID-${index}`">
            <div class="swiper-wrapper home-tabs-content" v-html="tab.content" />
          </div>
        </div>
      </SfTab>
    </SfTabs>
  </div>
</template>

<script>

import SfTabs from '@storefront-ui/vue/src/components/organisms/SfTabs/SfTabs.vue';
import { mapGetters } from 'vuex';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { directive } from 'vue-awesome-swiper'
import 'swiper/swiper.scss'
import { ref } from 'vue';
SwiperCore.use([Navigation, Pagination]);

export default {
  name: 'OHomeProductTabs',
  props: {
    sliderId: {
      type: String,
      default: 'homepage-tab'
    },
    content: {
      type: String,
      default: ''
    },
    navigation: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      swiperOption: {
        spaceBetween: 20,
        watchOverflow: true,
        init: true,
        observer: true,
        observeParents: true,
        breakpoints: {
          0: {
            slidesPerView: 1.3,
            allowTouchMove: true,
            centeredSlides: true,
            centeredSlidesBounds: true,
            spaceBetween: 15,
            loop: true
          },
          1024: {
            slidesPerView: 3,
            allowTouchMove: false,
            centeredSlides: false,
            centeredSlidesBounds: false,
            spaceBetween: 30,
            loop: false
          }
        }
      }
    }
  },
  components: {
    SfTabs
  },
  directives: {
    swiper: directive
  },
  computed: {
    ...mapGetters({
      cmsBlocks: 'cmsBlock/getCmsBlocks'
    }),
    homeTabs () {
      const tabBlocks = this.cmsBlocks.filter((_) => _.identifier.startsWith('home-product-tab-'))
      return tabBlocks
    }
  }
}

</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/variables";

#app .home-tabs-wrap {
  max-width: 84.57vw;
  margin: auto;
  width: 100%;
  min-height: 48vw;
  box-sizing: border-box;

  @include for-mobile {
    max-width: 100%;
    min-height: 600px;
    padding-bottom: 30px;
    padding-top: 20px;
  }

  * {
    box-sizing: border-box;
  }

  .sf-tabs {
    margin: 0;
    padding: 0;
    display: var(--display-flex);
    align-items: center;
    justify-content: center;
    list-style: none;
    --tabs-title-z-index: 1;
    --tabs-content-order: 1;
    --tabs-title-flex: 0 0 auto;

    .sf-button {
      font-size: 1.094vw;
      font-family: var(--font-family--primary);
      padding: 0 0 12px;
      margin: 0 30px;
      opacity: .5;
      color: var(--c-text-primary);
      cursor: pointer;
      text-transform: uppercase;
      font-weight: var(--font-weight--bold);
      border-bottom: 3px solid transparent;

      &.is-active {
        border-color: var(--c-text-primary);
        opacity: 1;

        @include for-mobile {
          pointer-events: none;
        }
      }

      &::before,
      .sf-chevron {
        display: none;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      @include for-mobile {
        font-size: 14px;
        margin: 20px 10px 0;
      }
    }

    .sf-tabs__title.is-active+.sf-tabs__content {
      border-top-width: 1px;
    }
  }

  .swiper-slider-wrap {
    padding: 0;
  }

  .sf-tabs__content {
    max-width: 100%;
  }

  .sf-tabs__content__tab {
    padding-left: 0;
    padding-right: 0;
  }

  .home-tabs-content {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .product-card-image {
      position: var(--relative);
      z-index: 0;

      a>img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 18.203vw;
        display: block;
        margin: auto;
        transition: all .4s ease;

        @include for-mobile {
          max-height: 47.111vw;
        }
      }

      >img {
        position: var(--absolute);
        top: var(--top-0);
        left: var(--bottom-0);
        right: var(--left-0);
        width: 100%;
        height: auto;
        transform: scale(0);
        transition: all .4s ease;
        z-index: -1;
        padding: 1.2vw;
        max-width: 100%;
      }
    }

    .product-card-dec {
      max-width: 80%;
      margin: auto;

      @include for-mobile {
        max-width: 88%;
      }

      h3 {
        font-size: 1.484vw;
        line-height: 1.875vw;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 0;

        @include for-mobile {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .product-review {
        a {
          display: inline-block;
          vertical-align: middle;
          line-height: 35px;
          margin-left: 10px;
          font-size: 1.25VW;
          font-weight: var(--font-weight--semibold);
          color: var(--c-text-primary);

          @include for-mobile {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .product-dec-hover {
        transition: all .4s ease;
        max-height: 0;
        transform: translateY(1em) scale(0);
        opacity: 0;
        visibility: hidden;
        height: 0;

        p {
          font-size: 1.094vw;
          font-weight: var(--font-weight--semibold);
          line-height: 1.563vw;
          margin: 15px 0 35px;

          @include for-mobile {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 20px;
          }
        }

        .sf-button {
          display: block;
          padding: 25px 0 21px;
          margin: 0;
          border-radius: 0.781vw;
          background-color: var(--c-theme-color);
          opacity: 1;

          @include for-mobile {
            font-size: 14px;
            padding: 14px 0 12px;
          }
        }
      }

      .product-review {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .product-stars {
        font-size: 28px;
        letter-spacing: -4px;
        line-height: 100%;

        @include for-mobile {
          font-size: 22px;
        }

        .start-icon::before {
          content: "★";
        }

        .star-icon-empty {
          color: var(--c-gray-variant);
        }

        .star-icon-half {
          position: relative;
          letter-spacing: 0px;
          margin-right: -4px;

          &::before {
            color: var(--c-gray-variant);
          }

          &::after {
            content: "★";
            position: absolute;
            left: 0;
            width: 50%;
            overflow: hidden;
          }
        }
      }
    }

    .tab-product-card {
      width: 33.33%;
      border-radius: 2.188vw;
      overflow: hidden;
      padding-bottom: 50px;
      height: 100%;

      @include for-mobile {
        width: 100%;
        padding-bottom: 30px;

        &.swiper-slide-active {
          .product-card-image {
            a>img {
              transform: scale(1.2);
            }

            >img {
              transform: scale(1);
            }
          }

          .product-dec-hover {
            opacity: 1;
            visibility: visible;
            max-height: 200px;
            transform: none;
            height: auto;
          }
        }
      }

      @include for-desktop {
        &:hover {
          .product-card-image {
            a>img {
              transform: scale(1.2);
            }

            >img {
              transform: scale(1);
            }
          }

          .product-dec-hover {
            opacity: 1;
            visibility: visible;
            max-height: 200px;
            transform: none;
            height: auto;
          }
        }
      }
    }
  }
}

.home-tabs-wrap {
  h2 {
    margin: 0 0 var(--spacer-lg);

    @include for-mobile {
      margin-bottom: 15px;
    }
  }
}
</style>
