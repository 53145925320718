<template>
  <div class="frontpagesquare-slider">
    <OSwiperSlider
      :swiper-option="swiperOption"
      :content="frontPageSquareBlock"
      :slider-id="sliderId"
      :navigation="false"
    />
  </div>
</template>

<script>
import OSwiperSlider from '~/theme/components/organisms/o-swiper/o-swiper-slider.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'OThreeSquare',
  props: {
    sliderId: {
      type: String,
      default: 'homepage-banners'
    }
  },
  data () {
    return {
      swiperOption: {
        loop: false,
        allowTouchMove: false,
        breakpoints: {
          0: {
            slidesPerView: '1.2',
            spaceBetween: 15,
            allowTouchMove: true
          },
          767: {
            slidesPerView: 3,
            spaceBetween: 20,
            allowTouchMove: true
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 25,
            allowTouchMove: false
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      cmsBlocks: 'cmsBlock/getCmsBlocks'
    }),
    frontPageSquareBlock () {
      const frontPageSquare = this.cmsBlocks.find((_) => _.identifier === 'frontpagesquare') || {}
      return frontPageSquare.content || ''
    }
  },
  components: {
    OSwiperSlider
  }
}
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/variables";

.frontpagesquare-slider {
  position: var(--relative);
  margin-top: var(--spacer-sm);
  .swiper-wrapper {
    display: flex;
    display: var(--banner-display, flex);
  }
  .swiper-slide {
    width: 33.3%;
    margin-right: var(--spacer-lg);
    &:last-child {
      margin-right: 0;
    }
  }
  .frontpagesquare-category-col {
    text-align: center;
    box-sizing: border-box;
    label {
      display: var(--display-block);
      position: var(--relative);
      overflow: hidden;
      padding-bottom: 100%;
    }
    .frontpagesquare-text {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding: 20px;
      box-sizing: border-box;
      color: #fff;
      h2 {
        margin-top: 0;
        margin-bottom: var(--spacer-sm);
      }
      p {
        margin-top: 0;
      }
      button {
        min-height: 3.25rem;
        width: var(--button-size, var(--button-width));
        height: var(--button-size, var(--button-height));
        display: var(--button-display, flex);
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        border: 0;
        padding: var(--spacer-2xs) var(--spacer-sm);
        text-decoration: var(--button-text-decoration);
        cursor: var(--button-cursor, pointer);
        border-radius: var(--button-border-radius, 0);
        white-space: var(--button-wrap, nowrap);
        font: var(--button-font, var(--button-font-weight, var(--font-weight--semibold)) var(--button-font-size, var(--font-size--base))/var(--button-font-line-height, 1.2) var(--button-font-family, var(--font-family--secondary)));
        border: var(--button-border, var(--button-border-style, solid) var(--button-border-color, var(--c-primary)));
        border-width: var(--button-border-width, 0);
        --button-box-shadow-opacity: 0;
        min-width: 150px;
        margin-left: auto;
        margin-right: auto;
        min-height: 41px;
      }
    }
    img {
      width: 100%;
      cursor: var(--pointer);
      position: var(--absolute);
      left: var(--left-0);
      right: var(--right-0);
      bottom: var(--bottom-0);
      top: var(--top-0);
      margin-right: var(--spacer-lg);
      object-fit: cover;
      height: 100%;
      transition: transform .5s;
    }
    a {
      display: var(--display-block);
      cursor: var(--pointer);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}
</style>

<style>
.frontpagesquare-slider .frontpagesquare-category-col .frontpagesquare-text {
  color: #fff;
}

.frontpagesquare-slider .frontpagesquare-category-col .frontpagesquare-text button {
  background: #fff;
  color: #fff;
}
</style>