<template>
  <div class="protein-top-category">
    <OSwiperSlider
      :swiper-option="swiperOption"
      :content="categorySliderBlock"
      :slider-id="sliderId"
    />
  </div>
</template>

<script>
import OSwiperSlider from '~/theme/components/organisms/o-swiper/o-swiper-slider.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'OTopCategory',
  props: {
    sliderId: {
      type: String,
      default: 'homepage-banners'
    }
  },
  data () {
    return {
      swiperOption: {
        loop: false,
        allowTouchMove: false,
        pagination: {
          el: ('#' + this.sliderId + '-pagination'),
          clickable: true
        },
        navigation: {
          nextEl: ('#' + this.sliderId + '-button-next'),
          prevEl: ('#' + this.sliderId + '-button-prev')
        },
        breakpoints: {
          0: {
            slidesPerView: 2,
            spaceBetween: 15,
            allowTouchMove: true
          },
          767: {
            slidesPerView: 4,
            spaceBetween: 15,
            allowTouchMove: true
          },
          1280: {
            slidesPerView: 5,
            spaceBetween: 32,
            allowTouchMove: false
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      cmsBlocks: 'cmsBlock/getCmsBlocks'
    }),
    categorySliderBlock () {
      const categorySlider = this.cmsBlocks.find((_) => _.identifier === 'category-slider') || {}
      return categorySlider.content || ''
    }
  },
  components: {
    OSwiperSlider
  }
}
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/variables";

.protein-top-category {
  position: var(--relative);
  margin-top: var(--spacer-sm);

  @include for-mobile {
    padding-left: var(--spacer-20);
    padding-right: var(--spacer-20);
  }

  .swiper-wrapper {
    display: flex;
    display: var(--banner-display, flex);
  }

  .swiper-slide {
    width: 222px;
    margin-right: var(--spacer-lg);

    &:last-child {
      margin-right: 0;
    }
  }

  .protein-top-category-col {
    text-align: center;
    box-sizing: border-box;

    img {
      width: 100%;
      cursor: var(--pointer);
      border-radius: var(--spacer-base);
      position: var(--absolute);
      left: var(--left-0);
      right: var(--right-0);
      bottom: var(--bottom-0);
      top: var(--top-0);
      margin-right: var(--spacer-lg);
      object-fit: cover;
      height: 100%;
      transition: transform .5s;

      @include for-mobile {
        border-radius: var(--spacer-xs);
      }
    }

    a {
      display: var(--display-block);
      cursor: var(--pointer);
    }

    label {
      display: var(--display-block);
      position: var(--relative);
      border-radius: var(--spacer-base);
      overflow: hidden;
      padding-bottom: 100%;
      margin-bottom: 8px;

      @include for-mobile {
        border-radius: var(--spacer-xs);
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .swiper-button-prev {
    position: var(--absolute);
    left: -8px;
    top: 50%;
    padding: var(--spacer-xs);
    margin-top: -50px;
    cursor: var(--pointer);
    display: var(--display-none);

    @include for-mobile {
      display: var(--display-block);
    }

    &.swiper-button-disabled {
      opacity: 0.5;
      cursor: var(--pointer-default);
    }
  }

  .swiper-button-next {
    position: var(--absolute);
    right: -8px;
    top: 50%;
    padding: var(--spacer-xs);
    margin-top: -50px;
    cursor: var(--pointer);
    display: var(--display-none);

    @include for-mobile {
      display: var(--display-block);
    }

    &.swiper-button-disabled {
      opacity: 0.5;
      cursor: var(--pointer-default);
    }
  }
}
</style>
