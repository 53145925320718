<template>
  <div id="home">
    <lazy-hydrate when-visible>
      <div class="promo-block" v-html="promoBlock" />
    </lazy-hydrate>
    <lazy-hydrate when-visible>
      <SfSection class="section home-section">
        <div v-html="ctaBanners[0]" class="bottom-banner animate__animated animate__slideInUp" />
      </SfSection>
    </lazy-hydrate>
    <lazy-hydrate when-idle>
      <MRecommendedProducts
        v-animate-onscroll="'animate__animated animate__slideInUp'"
        :class="'container'"
        :header="$t('Bestsellers')"
        slider-id="top"
        :products="bestSellersCarousel"
      />
    </lazy-hydrate>
    <MLetUsHelpBlocks
      v-animate-onscroll="'animate__animated animate__slideInUp'"
      class="letushelpBlock"
      :html="letUsHelpBlock"
      v-if="letUsHelpBlock != ''"
    />
    <lazy-hydrate when-visible>
      <OTopCategory v-animate-onscroll="'animate__animated animate__slideInUp'" :class="'container'" :slider-id="'homepage-category-banner'" />
    </lazy-hydrate>
    <lazy-hydrate when-idle>
      <MRecommendedProducts
        v-animate-onscroll="'animate__animated animate__slideInUp'"
        :class="'container'"
        :header="$t('Get the latest News')"
        slider-id="top"
        :products="newsPageRecommended"
      />
    </lazy-hydrate>
    <OThreeSquare v-animate-onscroll="'animate__animated animate__slideInUp'" :class="'container'" :slider-id="'frontpagesquare-slider'" />
    <lazy-hydrate when-idle>
      <div>
        <SfSection class="section">
          <div v-html="ctaBanners[1]" class="bottom-banner" v-animate-onscroll="'animate__animated animate__slideInUp'" />
        </SfSection>
      </div>
    </lazy-hydrate>
    <lazy-hydrate when-idle>
      <MRecommendedProducts
        v-animate-onscroll="'animate__animated animate__slideInUp'"
        :class="'container'"
        :header="$t('Recommended to you')"
        slider-id="bottom"
        :products="recommendedCarousel"
      />
    </lazy-hydrate>
    <lazy-hydrate when-idle>
      <OHomeProductTabs v-animate-onscroll="'animate__animated animate__slideInUp'" />
    </lazy-hydrate>
    <lazy-hydrate when-idle>
      <div>
        <SfSection class="section">
          <div v-html="ctaBanners[2]" v-animate-onscroll="'animate__animated animate__slideInUp'" class="bottom-banner" />
        </SfSection>
      </div>
    </lazy-hydrate>
    <lazy-hydrate when-idle>
      <MRecommendedProducts
        v-animate-onscroll="'animate__animated animate__slideInUp'"
        :class="'container'"
        :header="$t('Get protein enriched snacks and bars')"
        slider-id="top"
        :products="barsSnacksRecommended"
      />
    </lazy-hydrate>
    <lazy-hydrate when-idle>
      <div>
        <SfSection class="section">
          <div v-html="ctaBanners[3]" v-animate-onscroll="'animate__animated animate__slideInUp'" class="bottom-banner" />
        </SfSection>
      </div>
    </lazy-hydrate>
    <OBottomCategory :class="'container homepage-bottom-category'" v-animate-onscroll="'animate__animated animate__slideInUp'" :slider-id="'homepage-bottom-category-banner'" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';
import orderBy from 'lodash-es/orderBy';
import { Logger } from '@vue-storefront/core/lib/logger';
import { onlineHelper, isServer } from '@vue-storefront/core/helpers';
import SfSection from '@storefront-ui/vue/src/components/molecules/SfSection/SfSection.vue';
import MRecommendedProducts from '~/theme/components/molecules/m-recommended-products';
import MLetUsHelpBlocks from '~/theme/components/molecules/m-letus-help-blocks';
import OTopCategory from '~/theme/components/organisms/o-swiper/o-top-category';
import OThreeSquare from '~/theme/components/organisms/o-swiper/o-three-square';
import OHomeProductTabs from '~/theme/components/organisms/o-swiper/o-home-product-tabs';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { directive } from 'vue-awesome-swiper'
import 'swiper/swiper.scss'
import OBottomCategory from '~/theme/components/organisms/o-swiper/o-bottom-category';
import config from 'config'
import Vue from 'vue'
import VueAnimateOnScroll from 'vue-animate-onscroll'

Vue.use(VueAnimateOnScroll)

SwiperCore.use([Navigation, Pagination])

export default {
  name: 'Home',
  props: {
    sliderId: {
      type: String,
      default: 'homepage-banners'
    }
  },
  directives: {
    swiper: directive
  },
  components: {
    LazyHydrate,
    SfSection,
    MRecommendedProducts,
    OTopCategory,
    OThreeSquare,
    OHomeProductTabs,
    OBottomCategory,
    MLetUsHelpBlocks
  },
  data () {
    return {
      loading: true,
      loadNewsletterPopup: false,
      bestSellersCarousel: [],
      recentProducts: [],
      newsPageRecommended: [],
      barsSnacksRecommended: [],
      capsulesRecommended: [],
      strongerRecommended: [],
      weightlossRecommended: [],
      bundlesRecommended: [],
      balanceRecommended: [],
      recommendedCarousel: []
    };
  },
  computed: {
    ...mapState({
      isWebpSupported: state => state.ui.isWebpSupported
    }),
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      cmsBlocks: 'cmsBlock/getCmsBlocks',
      cartItems: 'cart/getCartItems'
    }),
    bannersGrid () {
      return {
        bannerA: this.cmsBlocks.find((_) => _.identifier === 'bannerA')?.content || '',
        bannerB: this.cmsBlocks.find((_) => _.identifier === 'bannerB')?.content || '',
        bannerC: this.cmsBlocks.find((_) => _.identifier === 'bannerC')?.content || '',
        bannerD: this.cmsBlocks.find((_) => _.identifier === 'bannerD')?.content || ''
      }
    },
    promoBlock () {
      const promoBlock = this.cmsBlocks.find((_) => _.identifier === 'homepage-promo-block') || {}
      return promoBlock.content || ''
    },
    ctaBanners () {
      const banners = this.cmsBlocks.filter((_) => _.identifier.startsWith('cta-banner-'))
      return orderBy(banners, 'identifier', 'asc').map(_ => _.content)
    },
    isOnline () {
      return onlineHelper.isOnline;
    },
    letUsHelpBlock () {
      const cmsBlock = this.cmsBlocks.find((_) => _.identifier === 'let-us-help-section-home') || {}
      return cmsBlock.content || ''
    }
  },
  methods: {
    async loadRetailProducts () {
      this.recentProducts = await JSON.parse(localStorage.getItem('recentProducts')) || []
      const storeBestSellersCarousel = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.frontPageBestSellersId)
      // store unique product for Bestseller Carousel
      const uniqueBestSellers = [...this.recentProducts, ...storeBestSellersCarousel].filter((item, index, array) => index === array.findIndex(obj => obj.id === item.id && obj.sku === item.sku))
      this.bestSellersCarousel = uniqueBestSellers
      // store unique product for Recommended Carousel
      this.recommendedCarousel = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.frontPageRecommendedId)
      this.newsPageRecommended = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.newsPageRecommendedId);
      this.barsSnacksRecommended = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.barsSnacksRecommendedId);
      this.capsulesRecommended = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.capsulesRecommendedId);
      this.strongerRecommended = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.strongerRecommendedId);
      this.weightlossRecommended = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.weightlossRecommendedId);
      this.bundlesRecommended = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.bundlesRecommendedId);
      this.balanceRecommended = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.balanceRecommendedId);
    }
  },
  async mounted () {
    await this.loadRetailProducts();
  },
  watch: {
    isLoggedIn () {
      const redirectObj = localStorage.getItem('redirect');
      if (redirectObj) this.$router.push(redirectObj);
      localStorage.removeItem('redirect');
    }
  },
  async asyncData ({ store, context }) {
    Logger.info('Calling asyncData in Home (theme)')();
    if (context) context.output.cacheTags.add(`home`)
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || this.$i18n.t('Home Page'),
      meta: this.$route.meta.description ? [{ vmid: 'description', name: 'description', content: this.$route.meta.description }] : []
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.section-recommended, .section-bestsellers {
    min-height: 23rem;
    @include for-desktop {
      min-height: 28rem;
    }
    transition: min-height .2s ease-out;
}
#home ::v-deep {
  box-sizing: border-box;
  contain-intrinsic-size: 1px 4900px;
  text-align: center;
  .container {
    max-width: var(--container-width);
    margin: 0 auto var(--spacer-lg);
  }
  @include for-desktop {
    margin: 0 auto;
    .protein-top-category, .frontpagesquare-slider {
      padding: 0 var(--spacer-sm);
    }
  }
  a.sf-button.sf-link {
    color: var(--c-light);
    text-decoration: none;
    display: inline-block;
    text-align: center;
    padding: var(--spacer-sm) var(--spacer-sm);
    font-family: var(--font-family--primary);
    font-size: var(--font-size--base);
    font-weight: var(--font-weight--bold);
    &:hover {
      color: var(--c-light-variant);
    }
  }
  .bottom-banner.animate__animated {
    animation-duration: 1.5s;
  }
  .banner-grid {
    &__wrapper {
      display: flex;
      width: inherit;
    }
  }
  .sf-banner-grid {
    margin: var(--spacer-base) 0;
    @include for-desktop {
      margin: var(--spacer-2xl) 0 var(--spacer-2xl);
    }
    &__row {
      @include for-desktop {
        min-height: 20rem;
      }
    }
    &__col {
      width: 100%;
      overflow: hidden;
    }
  }
  .sf-banner {
    --banner-title-margin: var(--spacer-2xs) 0 var(--spacer-xs) 0;
    min-height: 20rem;
    &__wrapper {
      @include for-desktop {
        pointer-events: auto;
        text-align: center;
      }
    }
    .sf-link:hover {
      color: var(--c-light-lighten);
    }
  }
  .cta-banner {
    min-height: 20rem;
    background: #f4f5f4;
  }
  .cta-banner .sf-banner {
    --banner-background-size: contain;
    --banner-background-position: 100%;
      overflow: hidden;
  }
  .cta-banner--reverse .sf-banner {
    @include for-desktop {
      --banner-background-position: 0;
      --banner-justify-content: flex-end;
    }
  }
  .sf-call-to-action {
    display: var(--banner-display, flex);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex-direction: var(--call-to-action-flex-direction, column);
    flex-direction: var(--call-to-action-flex-direction, column);
    -ms-flex-align: var(--call-to-action-align-items);
    align-items: var(--call-to-action-align-items);
    -ms-flex-pack: var(--call-to-action-justify-content, left);
    justify-content: var(--call-to-action-justify-content, left);
    min-height: var(--call-to-action-height, auto);
    padding: var(--call-to-action-padding, var(--spacer-lg));
    background: var(--call-to-action-background, var(--call-to-action-background-image, var(--_call-to-action-background-image)) var(--call-to-action-background-color, var(--_call-to-action-background-color, transparent)) no-repeat var(--call-to-action-background-position, 0% 0%));
    background-size: var(--call-to-action-background-size, cover);
    h1 {
     font-family: var( --font-family--primary);
     font-weight: var(--font-weight--medium);
    }
    @include for-desktop {
      --call-to-action-background-position: 60%;
      --call-to-action-description-font-size: var(--font-size--lg);
      --call-to-action-title-margin: 0 0 var(--spacer-xs) 0;
      --call-to-action-flex-direction: row;
      --call-to-action-justify-content: space-between;
      --call-to-action-align-items: center;
      --call-to-action-padding: var(--spacer-xl) var(--spacer-2xl);
      --call-to-action-height: 12.625rem;
      --call-to-action-text-container-width: 75%;
    }
  }
  .cta {
    --cta-min-height: 12.625rem;
    min-height: var(--cta-min-height);
    background: #f4f5f4;
    &--column {
      .sf-call-to-action {
        --call-to-action-flex-direction: column;
        --call-to-action-padding: var(--spacer-xl) var(--spacer-2xl) var(--spacer-base);
      }
    }
    .sf-call-to-action {
      --call-to-action-background-size: contain;
      --call-to-action-background-position: 100%;
      --call-to-action-color: var(--c-dark);
      --call-to-action-description-font-size: var(--font-size--base);
      --call-to-action-title-font-weight: var(--font-weight--semibold);
      --call-to-action-height: var(--cta-min-height);
      --button-background:  var(--c-dark);
      &__button {
        --link-color: var(--c-light);
        --c-link-hover: var(--c-light);
        align-self: center;
      }
    }
  }
}
</style>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/variables";

.home-section {
  min-height: 275px;

  @include for-mobile {
    min-height: inherit;
    margin: 0;
    > .sf-heading {
      padding-bottom: 0 !important;
    }
  }
  .sf-banner-responsive {
    >a {
      min-height: 132px;
      @include for-mobile {
        min-height: inherit;
      }
    }
    .protein-header-menu {
      min-height: 38px;
      @include for-mobile {
        min-height: inherit;
      }
    }
    .sf-banner-responsive {
      min-height: 345px;
      @include for-mobile {
        min-height: inherit;
      }
    }
    @include for-mobile {
      p {
        br {
          display: none;
        }
      }
    }
  }
}
#home {
  .container.homepage-bottom-category {
    @include for-mobile {
      margin-bottom: 0;
    }
  }
}
</style>
