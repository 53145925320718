<template>
  <div v-if="content">
    <div class="swiper-slider-wrap">
      <div v-swiper="swiperOption" :instance-name="sliderId">
        <div class="swiper-wrapper" v-html="content" />
      </div>
    </div>
    <div v-if="navigation" :id="sliderId + '-button-prev'" class="swiper-button-prev" slot="button-prev">
      <SfImage src="/assets/icons/prev.svg" alt="Previous" />
    </div>
    <div v-if="navigation" :id="sliderId + '-button-next'" class="swiper-button-next" slot="button-next">
      <SfImage src="/assets/icons/next.svg" alt="Next" />
    </div>
  </div>
</template>

<script>

import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { directive } from 'vue-awesome-swiper'
import 'swiper/swiper.scss'
SwiperCore.use([Navigation, Pagination]);

export default {
  name: 'OSwiperSlider',
  props: {
    sliderId: {
      type: String,
      default: 'homepage-banners'
    },
    swiperOption: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: String,
      default: ''
    },
    navigation: {
      type: Boolean,
      default: true
    }
  },
  directives: {
    swiper: directive
  },
  components: {
    SfImage
  }
}

</script>
